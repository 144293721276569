import phone2XWebp from './phone@2x.webp';
import phone2XPng from './phone@2x.png';
import phone1XWebp from './phone@1x.webp';
import phone1XPng from './phone@1x.png';
export const phoneImage = {
    src: phone1XPng.src,
    width: 379,
    height: 472,
    sources: [
        { width: 379, height: 472, srcSet: phone2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 379, height: 472, srcSet: phone2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 379, height: 472, srcSet: phone1XWebp.src },
        { width: 379, height: 472, srcSet: phone1XPng.src },
    ],
};
