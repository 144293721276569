import player1025Miw2XWebp from './player@1025miw@2x.webp';
import player1025Miw2XPng from './player@1025miw@2x.png';
import player1025Miw1XWebp from './player@1025miw@1x.webp';
import player1025Miw1XPng from './player@1025miw@1x.png';
import playerWebp from './player.webp';
import playerPng from './player.png';
export const playerImage = {
    src: playerPng.src,
    width: 982,
    height: 795,
    sources: [
        {
            width: 512,
            height: 348,
            srcSet: player1025Miw2XWebp.src,
            media: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 512,
            height: 348,
            srcSet: player1025Miw2XPng.src,
            media: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 512, height: 348, srcSet: player1025Miw1XWebp.src, media: '(min-width: 1025px)' },
        { width: 512, height: 348, srcSet: player1025Miw1XPng.src, media: '(min-width: 1025px)' },
        { width: 982, height: 795, srcSet: playerWebp.src },
        { width: 982, height: 795, srcSet: playerPng.src },
    ],
};
