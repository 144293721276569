import phone22XWebp from './phone_2@2x.webp';
import phone22XPng from './phone_2@2x.png';
import phone21XWebp from './phone_2@1x.webp';
import phone21XPng from './phone_2@1x.png';
export const phone2Image = {
    src: phone21XPng.src,
    width: 550,
    height: 436,
    sources: [
        { width: 550, height: 436, srcSet: phone22XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 550, height: 436, srcSet: phone22XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 550, height: 436, srcSet: phone21XWebp.src },
        { width: 550, height: 436, srcSet: phone21XPng.src },
    ],
};
