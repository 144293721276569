import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { analyticsDictionary, createAnalyticAttrs } from '@analytics';
import { CustomImg } from '@common/shared/ui/custom-img';
import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import Typograf from '@common/components/common/Typograf';
import { playerImage } from './images/player/player';
import { phone1Image } from './images/phone_1/phone_1';
import { phone2Image } from './images/phone_2/phone_2';
import { messages } from './messages';
import style from './style.module.scss';
const FeaturesBlock = () => {
    const intl = useIntl();
    return (<div className={style.features} {...createAnalyticAttrs(analyticsDictionary.landingFeaturesShow, ['show'])}>
      <ContentLayout>
        <div className={style.cardContainer}>
          <div className={classNames(style.card, style.card_item_1)} {...createAnalyticAttrs(analyticsDictionary.landingFeaturesItem('no_add'), ['show'])}>
            <div className={classNames(style.card__content)}>
              <h2 className={classNames(style.card__title, 'cui-title__promo', 'cui-title__promo_size_3')}>
                <Typograf>{intl.formatMessage(messages.item_1_title)}</Typograf>
              </h2>
              <p className={style.card__text}>
                <Typograf>{intl.formatMessage(messages.item_1_text)}</Typograf>
              </p>
            </div>

            <div className={style.card_item_1__img}>
              <CustomImg {...playerImage}/>
            </div>
          </div>

          <div className={style.row}>
            <div className={classNames(style.card, style.card_item_2)} {...createAnalyticAttrs(analyticsDictionary.landingFeaturesItem('offline'), ['show'])}>
              <div className={style.card__content}>
                <h2 className={classNames(style.card__title, 'cui-title__promo', 'cui-title__promo_size_3')}>
                  <Typograf>{intl.formatMessage(messages.item_2_title)}</Typograf>
                </h2>
                <p className={style.card__text}>
                  <Typograf>{intl.formatMessage(messages.item_2_text)}</Typograf>
                </p>
              </div>

              <div className={style.card__imgWrapper}>
                <CustomImg {...phone1Image} className={style.card__img_2}/>
              </div>
            </div>

            <div className={classNames(style.card, style.card_item_3)} {...createAnalyticAttrs(analyticsDictionary.landingFeaturesItem('off_screen'), ['show'])}>
              <div className={style.card__content}>
                <h2 className={classNames(style.card__title, 'cui-title__promo', 'cui-title__promo_size_3')}>
                  <Typograf>{intl.formatMessage(messages.item_3_title)}</Typograf>
                </h2>
                <p className={style.card__text}>
                  <Typograf>{intl.formatMessage(messages.item_3_text)}</Typograf>
                </p>
              </div>

              <div className={style.card__imgWrapper}>
                <CustomImg {...phone2Image} className={style.card__img_3}/>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    </div>);
};
export default FeaturesBlock;
