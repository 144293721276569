import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    title: {
        id: 'title',
        defaultMessage: 'СЛУШАЙТЕ ВКОНТАКТЕ ИЛИ В ПРИЛОЖЕНИИ VK МУЗЫКА',
    },
    text_p_1: {
        id: 'text_p_1',
        defaultMessage: 'Что бы вы ни выбрали, с вами будут сохранённые треки, эксклюзивные релизы артистов, подкасты, а также рекомендации: подборки от алгоритмов, пользователей, сообществ и кураторов. А ещё можно слушать VK Микс: бесконечный плейлист, который подстраивается под ваши вкусы.',
    },
    img_alt: {
        id: 'img_alt',
        defaultMessage: 'Приложение VK Музыка',
    },
});
