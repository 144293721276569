import phone12XWebp from './phone_1@2x.webp';
import phone12XPng from './phone_1@2x.png';
import phone11XWebp from './phone_1@1x.webp';
import phone11XPng from './phone_1@1x.png';
export const phone1Image = {
    src: phone11XPng.src,
    width: 495,
    height: 436,
    sources: [
        { width: 495, height: 436, srcSet: phone12XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 495, height: 436, srcSet: phone12XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 495, height: 436, srcSet: phone11XWebp.src },
        { width: 495, height: 436, srcSet: phone11XPng.src },
    ],
};
